<template>
  <div style="margin-top: 20vh">
    <v-container>
      <v-row>
        <v-col cols="12" md="6" offset-md="3">
          <v-card class="pa-4 py-10">
            <h4 class="primary--text">Ready to work? Kindly login</h4>
            <v-form
              lazy-validation
              ref="form"
              class="mt-5"
              @submit.prevent="$refs.form.validate() ? loginUser() : null"
            >
              <v-text-field
                outlined
                v-model="form.email"
                dense
                placeholder="Email Address"
                :rules="required.requiredFormFields('email')"
              ></v-text-field>
              <v-text-field
                outlined
                v-model="form.password"
                dense
                placeholder="Password"
                type="password"
                :rules="required.requiredFormFields('password')"
              ></v-text-field>
              <v-btn block color="primary" type="submit" :loading="loading"
                >Login
              </v-btn>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <response-message
      v-if="showResponse"
      :response-message="responseMessage"
      :response-type="responseType"
      :show-response="showResponse"
    ></response-message>
  </div>
</template>

<script>
import ResponseMessage from "../../components/ResponseMessage";
import { mapActions } from "vuex";
import Helpers from "../../utils/helpers";

export default {
  name: "LoginPage",
  components: {
    ResponseMessage,
  },
  data() {
    return {
      form: {},
      required: new Helpers(),
      loading: false,
      showResponse: false,
      responseMessage: "",
      responseType: "",
    };
  },
  methods: {
    ...mapActions("auth", ["login_user"]),
    async loginUser() {
      this.showResponse = false;
      this.loading = true;
      try {
        await this.login_user(this.form);
        this.loading = false;
        const intendedRoute = this.$route.query.redirect ?? "/dashboard";
        await this.$router.replace({ path: intendedRoute });
      } catch (e) {
        this.loading = false;
        this.responseType = "error";
        this.responseMessage = e?.response?.data?.message;
        this.showResponse = true;
      }
    },
  },
};
</script>

<style scoped></style>
